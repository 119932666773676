import React, { useEffect, useState } from "react";

import { grapesjs } from "grapesjs";
import customGrapes from '../components/grapesjs/index'
import codeEditor from '../components/grapesjs-component-code-editor/src';
import thePlugin from 'grapesjs-plugin-export';
// import editorPlugin from 'grapesjs-script-editor';
import newsletter from 'grapesjs-preset-newsletter';
import postcss from 'grapesjs-parser-postcss'
import axios from "axios";
import { Select, Button, Drawer, Col, Row, Flex, Input, message, Tag } from "antd";
import embedVideo from 'grapesjs-video-embed-manager'
import { BrowserRouter as Router, Route, Switch, Link, useHistory, Prompt } from 'react-router-dom';


import 'grapesjs/dist/css/grapes.min.css'
import 'grapesjs/dist/grapes.min.js'
import '../components/grapesjs/index.css'
import '../components/grapesjs-component-code-editor/dist/grapesjs-component-code-editor.min.css'

import './index.css'


const router = process.env.REACT_APP_BASE_URL

function WebPageCreator() {
  const history = useHistory();
  const [messageApi, contextHolder] = message.useMessage();

  const [styles, setStyles] = useState([]);
  const [scripts, setScripts] = useState(['https://cdn.tailwindcss.com']);
  const [open, setOpen] = useState(false);
  const [build, setBuild] = useState(false);

  const [save, setSave] = useState(false);
  const [i, setI] = useState([]);

  const [nomeProjeto, setNomeProjeto] = useState('')
  const [loadings, setLoadings] = useState(false);

  const [idTemplate, setIdtemplate] = useState(null)
  const [projetoData, setProjetoData] = useState(null)



  useEffect(() => {
    const escapeName = (name) => `${name}`.trim().replace(/([^a-z0-9\w-:/]+)/gi, '-');

    const editor = grapesjs.init({
      height: '80vh',
      container: '#gjs',
      showOffsets: true,
      fromElement: true,
      noticeOnUnload: false,
      storageManager: false,
      allowScripts: 1,
      canvas: {
        styles: styles,
        scripts: scripts,
      },

      selectorManager: { escapeName },
      plugins: [
        // editorPlugin,
        codeEditor,
        thePlugin,
        'grapesjs-tailwind',
        postcss,
        embedVideo,
        newsletter
      ],
      pluginsOpts: {
        newsletter: {},
        'grapesjs-tailwind': {},
        codeEditor: {},
        thePlugin: {},
        // editorPlugin: {},
        embedVideo: {
          preloader: 'https://gifimage.net/wp-content/uploads/2018/04/loading-icon-gif-6.gif',
          resources: ['youtube', 'vimeo', 'local'],
          per_page: 5,
          youtubeLoadCallback: (data) => data,
          youtubeLoadUrl: '/fake/callback'
        }
      },
      storageManager: {
        type: 'local',
        autosave: true,
        autoload: true,
        stepsBeforeSave: 1,
        options: {
          local: { key: 'webTemplate' }
        }
      }
    });

    editor.on('storage:store', (e) => {
      console.log('Before saving to localStorage:', e);
      setSave(true)
    });

    editor.Panels.addButton('options', {
      id: 'update-theme',
      className: 'fa fa-adjust',
      command: 'open-update-theme',
      attributes: {
        title: 'Update Theme',
        'data-tooltip-pos': 'bottom',
      },
    });

    editor.Panels.addButton('options', {
      id: 'export',
      className: 'fa fa-download',
      command: 'open-export',
      attributes: {
        title: 'Download Code',
        'data-tooltip-pos': 'bottom',
      },
    });

    const pn = editor.Panels;
    const panelViews = pn.addPanel({
      id: 'views'
    });

    panelViews.get('buttons').add([{
      attributes: {
        title: 'Open Code'
      },
      className: 'fa fa-file-code-o',
      command: 'open-code',
      id: 'open-code'
    }]);

    customGrapes(editor)
    setI(editor)
  }, [styles, scripts])


  useEffect(() => {
    if (!save) return;

    const handleBlockNavigation = (location, action) => {
      if (save) {
        const message = 'Você tem alterações não salvas. Tem certeza de que deseja sair?';
        if (window.confirm(message)) {
          unblock();
          history.push(location.pathname);
        } else {
          return false;
        }
      }
    };

    const unblock = history.block(handleBlockNavigation);

    return () => {
      unblock();
    };
  }, [save, history]);


  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (save) {
        const message = 'Você tem alterações não salvas. Tem certeza de que deseja sair?';
        event.returnValue = message; // Este é o padrão para alguns navegadores
        return message; // Este é o padrão para outros navegadores
      }
    };

    // Adicione o listener para o evento beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Limpe o listener quando o componente desmontar ou quando templateChange mudar
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [save]);


  useEffect(() => {
    const queryString = window.location.search;

    // Cria uma nova instância de URLSearchParams
    const urlParams = new URLSearchParams(queryString);

    // Pega os parâmetros individuais

    if (urlParams.has('id')) {
      const id = urlParams.get('id');
      if (id) {
        const dataTemplate = JSON.parse(localStorage.getItem('data'))
        setIdtemplate(dataTemplate?.id)
        setNomeProjeto(dataTemplate?.name)
        setProjetoData(dataTemplate)
      }
    }
  }, [])



  const saveWebTemplate = () => {
    setLoadings(true)
    const template = localStorage.getItem('webTemplate')

    axios
      .post(`${router}/web/template`, {
        name: nomeProjeto,
        draft_content: template
      })
      .then(response => {
        setLoadings(false)

        success()
        setSave(false)
      })
      .catch(e => {
        setLoadings(false)
        error()
      })
  }

  const updateWebTemplate = () => {
    setLoadings(true)
    const template = localStorage.getItem('webTemplate')

    axios
      .put(`${router}/web/template`, {
        id: idTemplate,
        name: nomeProjeto,
        draft_content: template
      })
      .then(response => {
        setLoadings(false)

        success()
        setSave(false)
      })
      .catch(e => {
        setLoadings(false)
        error()
      })
  }

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const publicarTemplte = () => {
    setBuild(true)
  }


  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Template salvo com sucesso!',
    });
  };

  const error = () => {
    messageApi.open({
      type: 'error',
      content: 'Ocorreu um erro. Por favor, entre em contato com o suporte.',
    });
  };

  const formateStatus = (data) => {
    let color = 'gray'
    let status = data?.published_content ? 'Publicado' : 'Rascunho';
    if (status === 'Publicado') {
      color = 'green';
    }
    return (
      <Tag color={color} key={status}>
        {status.toUpperCase()}
      </Tag>
    )
  }

  return (
    <>
      {contextHolder}
      <Row >
        <Col span={24}>
          <Row style={{ margin: '10px 0px' }}>
            <Col className="center" span={18}>
            </Col>
            <Col className="center" style={{ justifyContent: 'flex-end' }} span={6}>
              <Button disabled={!save} loading={loadings} className="background borderRadium" onClick={(e) => !idTemplate ? saveWebTemplate(e) : updateWebTemplate(e)}>
                {save ? 'Salvar' : 'Sem Alteração'}
              </Button>
              <Button className="background borderRadium" onClick={showDrawer}>
                Configurações
              </Button>
            </Col>
          </Row>
          <div class="card card-1">
            <Row >
              <Col className="center pading15" span={12}>
                <label style={{ width: '100%' }} >Nome do Projeto
                  <Input width={'100%'} required placeholder="Digite o nome do projeto" variant="filled" onChange={(e) => setNomeProjeto(e.target.value)} value={nomeProjeto} />
                </label>
              </Col>
              <Col className="center pading15" span={6}>
                {/* <label style={{ width: '100%' }}>Empresa
                  <Input width={'100%'} required placeholder="Digite o nome da empresa" variant="filled" />
                </label> */}
              </Col>
              <Col className="centerEnd pading15" span={6}>
                  {formateStatus(projetoData)}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Drawer title="Basic Drawer" onClose={onClose} open={open}>
        <Row >
          <Col span={24} className="margin15">
            <label>CDN Scripts</label>
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="CDN Scripts"
              onChange={(e) => setScripts(e)}
              value={scripts}
            />
          </Col>
          <Col span={24} className="margin15">
            <label>CDN Styles</label>
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="CDN Styles"
              onChange={(e) => setStyles(e)}
              value={styles}
            />
          </Col>
        </Row>


        <div id="mybutton">
          <Button className="feedback" type="primary" onClick={() => publicarTemplte()} loading={build} iconPosition={"end"}>
            {build ? 'Publicando...' : 'Publicar'}
          </Button>
        </div>
      </Drawer>




      <div id="gjs" />
    </>
  );
}

export default WebPageCreator;
