import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Space, Table, Tag } from 'antd';
import { useHistory } from "react-router-dom";

import axios from "axios";

import './index.css'


const router = process.env.REACT_APP_BASE_URL

function ListWebPage() {
  const navigate = useHistory();

  const [templates, setTemplates] = useState([])

  const handleClick = () => {
    localStorage.clear('webTemplate')
    localStorage.clear('data')
    navigate.push(`/admin/criador/site`);
  }

  const editTemplate = ({ id, data }) => {
    localStorage.setItem('webTemplate', data.draft_content)
    localStorage.setItem('data', JSON.stringify(data))

    navigate.push(`/admin/criador/site?id=${id}`);
  }

  const formatDate = (date) => {
    let time = ` - `
    if (date) {
      return `${new Date(date).getDate()}/${new Date(date).getMonth() + 1}/${new Date(date).getFullYear()}`
    }
    return time
  }

  const formateStatus = (data) => {
    let color = 'gray'
    let status = data.published_content ? 'Publicado' : 'Rascunho';
    if (status === 'Publicado') {
      color = 'green';
    }
    return (
      <Tag color={color} key={status}>
        {status.toUpperCase()}
      </Tag>
    )
  }

  const columns = [
    {
      title: '#ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Template',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Data Criação',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => formatDate(date),
    },
    {
      title: 'Status',
      key: 'name',
      dataIndex: 'status',
      render: (_, data) => formateStatus(data),
    },
    {
      title: 'Ações',
      key: 'action',
      render: (data) => (
        <Space size="middle">
          <a onClick={() => editTemplate({ id: data.id, data })}><b>Editar</b></a>
        </Space>
      ),
    },
  ];

  const getWebTemplate = async () => {
    const { data } = await axios
      .get(`${router}/web/template`, {
        headers: {
          'Content-Type': 'application/json ',
        }
      })

    setTemplates(data?.data)
    console.log('template', data)
  }

  useEffect(() => {
    getWebTemplate()
  }, [])


  return (
    <>
      <Row >
        <Col span={24}>
          <Row style={{ margin: '10px 0px' }}>
            <Col className="center" span={18}>
            </Col>
            <Col className="center" style={{ justifyContent: 'flex-end' }} span={6}>
              <Button className="background borderRadium" onClick={() => handleClick()}>
                Criar Novo Site
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row >
        <Col span={24}>
          <Table columns={columns} dataSource={templates} />
        </Col>
      </Row>
    </>
  );
}

export default ListWebPage;
